/* Provide sufficient contrast against white background */
body {
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
}

a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.streetViewPOILayer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.testCanvas {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  width: 100%;
  z-index: 1;
}

.streetViewPOILayerInner {
  position: relative;
  width: 100%;
}

.streetViewPOILayerInner .poiPoint {
  position: absolute;
  z-index: 10;
}

.poiIcon {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: red;
}
div.gm-style div div div.gmnoprint {
  top: 50px !important;
}

div.gm-style div div div.gmnoprint svg path {
  d: path('M 0 -100 L 25 -75 C 31 -69 21 -59 15 -65 L 0 -82 L -15 -65 C -21 -59 -31 -69 -25 -75');
}

a[title^='Open this area in Google Maps'] {
  display: none !important;
}

.adminText{
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
  color: #555555;
  pointer-events: none;
  border-radius: 50px;
  background: #f8f9fa;
  font-weight: 600;
  padding: 0 8px;
  box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); 
  -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); 
  -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);
}

@keyframes pricePulse {
  0% {
    background-color: red;
  }
  50% {
    background-color: #222;
  }
  100% {
    background-color: red;
  }
}

@keyframes colorChange {
  0% {
    color: red;
  }
  50% {
    color: #ff9900;
  }
  100% {
    color: red;
  }
}
@keyframes rotateIcon {
  0% {
    transform: rotate(0deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dotPulse {
  0% {
    background-color: red;
  }
  50% {
    background-color: #ff7c7c;
  }
  100% {
    background-color: red;
  }
}

@keyframes boxShadowPulse {
  0% {
    box-shadow: 1px 1px 9px 0px #e9ecef; -webkit-box-shadow: 1px 1px 9px 0px #e9ecef; -moz-box-shadow: 1px 1px 9px 0px #e9ecef;
  }
  50% {
    box-shadow: none; -webkit-box-shadow: none; -moz-box-shadow: none;
  }
  100% {
    box-shadow: 1px 1px 9px 0px #e9ecef; -webkit-box-shadow: 1px 1px 9px 0px #e9ecef; -moz-box-shadow: 1px 1px 9px 0px #e9ecef;
  }
}

#fxo-widget-iframe,
.fxo-widget-iframe {
  display: none;
}

.fxo-messenger-iframe-container {
  display: none;
}

.loadingScreen {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #202020;
  top: 0;
  left: 0;
  z-index: 1301;
  align-items: center;
  justify-content: center;
}
/* 
@mixin fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;
}

@keyframes fadeInOpacity {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
} */

/*Hide Google Logo*/
a[href^="http://maps.google.com/maps"]{display:none !important}
a[href^="https://maps.google.com/maps"]{display:none !important}

/* -----Hide input arrow-----*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
